/* Type */

	body {
		background-color: _palette(bg);
		color: _palette(fg);
	}

	body, input, select, textarea {
		font-family: _font(family);
		font-size: 14.75pt;
		font-weight: _font(weight);
		line-height: 1.82;

		@include breakpoint('<=xlarge') {
			font-size: 14.75pt;
		}

		@include breakpoint('<=large') {
			font-size: 12pt;
		}

		@include breakpoint('<=medium') {
			font-size: 12pt;
		}

		@include breakpoint('<=small') {
			font-size: 12pt;
		}

		@include breakpoint('<=xsmall') {
			font-size: 12pt;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	strong, b {
		font-weight: _font(weight-bold);
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 _size(element-margin) 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-weight: _font(weight-bolder);
		letter-spacing: 0.2em;
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.4) 0;
		text-transform: uppercase;

		a {
			color: inherit;
			text-decoration: none;
		}
	}
	h1 {
		font-size: 2.1em;
	}

	h2 {
		font-size: 1.8em;
	}

	h3 {
		font-size: 1.5em;
	}

	h4 {
		font-size: 1.1em;
	}

	h5 {
		font-size: 0.9em;
	}

	h6 {
		font-size: 0.7em;
	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid (_size(border-width) * 4);
		font-style: italic;
		margin: 0 0 _size(element-margin) 0;
		padding: (_size(element-margin) / 4) 0 (_size(element-margin) / 4) _size(element-margin);
	}

	code {
		border-radius: _size(border-radius);
		border: solid _size(border-width);
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0.25em;
		padding: 0.25em 0.65em;
	}

	pre {
		-webkit-overflow-scrolling: touch;
		font-family: _font(family-fixed);
		font-size: 0.9em;
		margin: 0 0 _size(element-margin) 0;

		code {
			display: block;
			line-height: 1.75;
			padding: 1em 1.5em;
			overflow-x: auto;
		}
	}

	hr {
		border: 0;
		border-bottom: solid _size(border-width);
		margin: _size(element-margin) 0;

		&.major {
			margin: (_size(element-margin) * 1.5) 0;
		}
	}

	.align-left {
		text-align: left;
	}

	.align-center {
		text-align: center;
	}

	.align-right {
		text-align: right;
	}

	@mixin color-typography($p: null) {
		$highlight: _palette($p, highlight);

		@if $p != null {
			background-color: _palette($p, bg);
			color: _palette($p, fg);
		}

		input, select, textarea {
			color: _palette($p, fg-bold);
		}

		a {
			color: _palette($highlight, bg);
		}

		strong, b {
			color: _palette($p, fg-bold);
		}

		h1, h2, h3, h4, h5, h6 {
			color: _palette($p, fg-bold);
		}

		blockquote {
			border-left-color: _palette($p, border);
		}

		code {
			background: _palette($p, border-bg);
			border-color: _palette($p, border);
		}

		hr {
			border-bottom-color: _palette($p, border);
		}
	}

	@include color-typography;
