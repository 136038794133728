/* Menu */

	$menu-palette: accent1;

	#menu {
		@include vendor('transform', 'translateX(-100%)');
		@include vendor('transition', ('transform #{_duration(menu)} ease', 'box-shadow #{_duration(menu)} ease', 'visibility #{_duration(menu)}'));
		-webkit-overflow-scrolling: touch;
		background: _palette($menu-palette, bg);
		box-shadow: none;
		height: 100%;
		left: 0;
		max-width: 80%;
		overflow-y: auto;
		padding: 3em 2em;
		position: fixed;
		top: 0;
		visibility: hidden;
		width: 20em;
		z-index: _misc(z-index-base) + 2;

		> ul {
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&.links {
				list-style: none;
				padding: 0;

				> li {
					font-weight: _font(weight-bold);
					letter-spacing: 0.15em;
					padding: 0;
					text-transform: uppercase;

					> a:not(.button) {
						border: 0;
						border-top: solid 1px _palette($menu-palette, border);
						color: inherit;
						display: block;
						letter-spacing: _size(letter-spacing-alt);
						line-height: 3.5em;
						text-decoration: none;
						font-size: 0.9em;
					}

					> .button {
						display: block;
						margin: 0.5em 0 0 0;
					}

					&:first-child {
						> a:not(.button) {
							border-top: 0 !important;
						}
					}
				}
			}
		}

		.close {
			@include icon(false, solid);
			@include vendor('transition', 'color #{_duration(transition)} ease-in-out');
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			border: 0;
			color: _palette($menu-palette, fg-light);
			cursor: pointer;
			display: block;
			height: 3.25em;
			line-height: 3.25em;
			padding-right: 1.25em;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			vertical-align: middle;
			width: 7em;

			&:before {
				content: '\f00d';
				font-size: 1.25em;
			}

			&:hover {
				color: _palette($menu-palette, fg);
			}

			@include breakpoint('<=small') {
				height: 4em;
				line-height: 4em;
			}
		}

		&.visible {
			@include vendor('transform', 'translateX(0)');
			box-shadow: 0 0 4em 0 rgba(0,0,0,0.1);
			visibility: visible;
		}

		@include breakpoint('<=small') {
			padding: 2.5em 1.75em;
		}
	}
