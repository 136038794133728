// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.5s,
		transition:			0.2s
	);

// Size.
	$size: (
		border-radius:		4px,
		border-width:		2px,
		element-height:		2.75em,
		element-margin:		1em,
		inner: 				58.5em
	);

// Font.
	$font: (
		family:				('Ubuntu', Arial, Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		400,
		weight-bolder:		600
	);

// Palette.
	$palette: (
		bg:					#fff,
		fg:					#484459,
		fg-bold:			#413D53,
		fg-light:			#999,
		border:				rgba(144,144,144,0.25),
		border-bg:			rgba(144,144,144,0.15),
		border2:			rgba(144,144,144,0.5),
		border2-bg:			rgba(144,144,144,0.2),
		highlight:			accent3,

		accent1: (
			bg:				#fff,
			fg:				#484459,
			fg-bold:		#413D53,
			fg-light:		#999,
			border:			rgba(144,144,144,0.25),
			border-bg:		rgba(144,144,144,0.15),
			border2:		rgba(144,144,144,0.5),
			border2-bg:		rgba(144,144,144,0.2),
			highlight:		accent3,
		),

		accent2: (
			bg:				#7cafa3,
			fg:				#fff,
			fg-bold:		#fff,
			fg-light:		#a8cebd,
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent3: (
			bg:				#6c85ab,
			fg:				#fff,
			fg-bold:		#fff,
			fg-light:		#bec4dd,
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		),

		accent4: (
			bg:				#b67e96,
			fg:				#fff,
			fg-bold:		#fff,
			fg-light:		#81c1cd,
			border:			rgba(255,255,255,0.15),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(255,255,255,0.5),
			border2-bg:		rgba(255,255,255,0.2),
			highlight:		accent1
		)
	);
