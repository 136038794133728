/* Header */

	@include keyframes('reveal-header') {
		0% { top: -4em; opacity: 0; }
		100% { top: 0; opacity: 1; }
	}

	#header {
		@include vendor('animation', 'none');
		@include vendor('display', 'flex');
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base) + 1;
		padding: 1em;
		-ms-flex-pack: justify;

		> a {
			color: inherit;
			font-size: 0.75em;
			font-weight: _font(weight-bold);
			letter-spacing: 0.13em;
			height: 3em;
			line-height: 3em;
			text-decoration: none;
			text-transform: uppercase;
			margin-left: 2em;

			&.button {
				padding: 0 1.5em;
			}

			&:first-child {
				margin-left: 0;
			}

			&[href="#menu"] {
				@include icon(false, solid);
				margin-right: auto;
				-webkit-tap-highlight-color: rgba(0,0,0,0);
				padding-left: 0.5em;

				&:before {
					content: '\f0c9';
					line-height: inherit;
					margin: 0 0.5em 0 0;
				}
			}
		}

		&.alt {
			@include vendor('animation', 'reveal-header 0.5s ease');
			box-shadow: 0 0 0.15em 0 rgba(0,0,0,0.1);
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background-color: transparentize(_palette(bg), 0.05);
			padding: 0.5em;

			a {
				&[href="#menu"] {
					padding-left: 1em;
				}
			}
		}
	}

	@include breakpoint('<=medium') {
		#header {
			> h1 {
				left: 1em;

				a {
					font-size: 1em;
				}
			}
		}
	}

	@include breakpoint('<=xsmall') {
		#header {
			padding: 0.5em;
			min-width: 320px;

			&.alt {
				padding: 0.5em;

				a {
					&[href="#menu"] {
						padding-left: 0.5em;
					}
				}
			}
		}
	}
