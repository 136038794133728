/* Alt Icons */

	ul.alt-icons {
		@include vendor('display', 'inline-flex');
		border-radius: _size(border-radius);
		border: solid _size(border-width);
		list-style-type: none;
		padding-left: 0;

		> li {
			border-left: solid _size(border-width);
			height: 2.5em;
			line-height: 2.5em;
			padding: 0;
			width: 2.6em;

			&:first-child {
				border-left: 0;
			}
		}

		.icon {
			display: block;
			height: 100%;
			width: 100%;
			transition: color _duration(transition), background-color _duration(transition);

			&:before {
				font-size: 1.1em;
				line-height: inherit;
			}
		}
	}

	@mixin color-alt-icons($p: null) {
		ul.alt-icons {
			border-color: _palette($p, border);

			li {
				border-left-color: _palette($p, border);
			}

			.icon {
				color: _palette($p, fg-light);

				&:hover {
					background-color: _palette($p, border-bg);
					color: _palette($p, fg);
				}

				&:active {
					background-color: _palette($p, border2-bg);
					color: _palette($p, fg);
				}
			}
		}
	}

	@include color-alt-icons;
