/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out');
		border-radius: _size(border-radius);
		border: solid _size(border-width);
		cursor: pointer;
		display: inline-block;
		font-size: 0.75em;
		font-weight: _font(weight-bold);
		height: 3.7em;
		letter-spacing: 0.13em;
		line-height: 3.6em;
		padding: 0 2.9em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			border-bottom: solid _size(border-width);

			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			width: 100%;
		}

		&.small {
			font-size: 0.6em;
		}

		&.large {
			font-size: 1.35em;
			height: 3em;
			line-height: 2.9em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}

		@include breakpoint('<=xsmall') {
			padding: 0;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			border-color: _palette($p, border);
			color: _palette($p, fg-bold) !important;

			&:hover {
				background-color: _palette($p, border-bg);
			}

			&:active {
				background-color: _palette($p, border2-bg);
			}

			&.icon {
				border-bottom-color: _palette($p, border);

				&:before {
					color: _palette($p, fg-light);
				}
			}

			&.primary {
				background-color: _palette($p, fg);
				border-color: transparent;
				color: _palette($p, bg) !important;

				&:hover {
					background-color: lighten(_palette($p, fg), 7.5);
				}

				&:active {
					background-color: darken(_palette($p, fg), 7.5);
				}
			}
		}
	}

	@include color-button;
