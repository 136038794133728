/* Spotlights */

	.spotlights {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		margin-bottom: 0;

		> section {
			@include padding(3em, 2.5em);
			position: relative;
			width: 50%;

			.image {
				margin-bottom: _size(element-margin);
				max-width: 100%;

				> img {
					max-width: 100%;
				}
			}

			&:before {
				// Simulate a full-width top border
				content: '';
				height: _size(border-width);
				position: absolute;
				top: 0;
				width: 100vw;
			}

			&:nth-child(odd) {
				border-right: solid _size(border-width);

				&:before {
					right: 0;
				}
			}

			&:nth-child(even) {
				&:before {
					left: 0;
				}
			}

			&:last-child {
				padding-bottom: 3.5em;
			}
		}

		@include breakpoint('<=medium') {
			> section {
				@include padding(2.5em, 2em);

				&:last-child {
					padding-bottom: 2em;
				}
			}
		}

		@include breakpoint('<=xsmall') {
			> section {
				@include padding(2.5em, 1.5em);
				width: 100%;

				&:nth-child(odd) {
					border-right: 0;
				}

				&:last-child {
					padding-bottom: 0.5em;
				}
			}
		}
	}

	@mixin color-spotlights($p: null) {
		.spotlights {
			> section {
				&:before {
					background-color: _palette($p, border-bg);
				}

				&:nth-child(odd) {
					border-right-color: _palette($p, border-bg);
				}
			}
		}
	}

	@include color-spotlights;
