body a {
  color: #5b5b5b;
}

body.landing {
  background-attachment: scroll !important;
  background-color: #f4f4f4;
}

body.single {
  background-image: none;

}

h1, h2, h3, h4, h5, h6, header.major h1, .icon.style3 {
  color: #404040;
}

strong, b {
    font-weight: 700;
}

header.major {
  padding: 5.5em 0 .5em 0;
}

.logo {
    margin-right: 21px;
}

.features {
  margin-bottom: 0px;
  border: none;
}
.features h4 {
  padding-top: 1em;
}

#banner {
    padding: 6em 2em 5em 2em;
}

.wave-container > svg {
  display: block;
  margin-bottom: -1px;
}

#main .inner {
    border-radius: .3em;
}

.no-padding {
  padding: 0px !important;
}

.hero {
    display: flex;
    max-height: 35vw;
    overflow: hidden;
    align-items: center;
    margin-bottom: 2.4em;
    border-top-left-radius: .3em;
    border-top-right-radius: .3em;
}

.hero > .image.fit {
  border-radius: 0px;
}

.meta  {
  -moz-align-items: left;
  -webkit-align-items: left;
  -ms-align-items: left;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-justify-content: left;
  -webkit-justify-content: left;
  -ms-justify-content: left;
  justify-content: left;
  border-left: 0;
  margin: 0 0 1em 0;
  padding-top: 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

.meta > * {
    border-left: solid 1px rgba(160, 160, 160, 0.3);
    margin-left: 2em;
    padding-left: 2em;
  }

.meta .published {
    color: #3c3b3b;
    display: block;
    font-size: 0.7em;
    font-weight: 800;
    letter-spacing: 0.25em;
    margin-top: 0.5em;
    text-transform: uppercase;
    white-space: nowrap;
}

.meta > :first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}

.meta .author .name {
    margin: 0 0 0 1.5em;
  }

.author {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: -moz-flex-end;
    -webkit-justify-content: -webkit-flex-end;
    -ms-justify-content: -ms-flex-end;
    justify-content: flex-end;
    border-bottom: 0;
    font-size: 0.6em;
    font-weight: 400;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    white-space: nowrap;
  }

.author .name {
    -moz-transition: border-bottom-color 0.2s ease;
    -webkit-transition: border-bottom-color 0.2s ease;
    -ms-transition: border-bottom-color 0.2s ease;
    transition: border-bottom-color 0.2s ease;
    border-bottom: dotted 1px rgba(160, 160, 160, 0.65);
    display: block;
    margin: 0 1.5em 0 0;
  }

.author:hover .name {
  border-bottom-color: transparent;
}

a.author {
  text-decoration: none;
}

.author img {
    border-radius: 100%;
    display: block;
    width: 4em;
}

.post {
  padding: 1em 1em 0em 1em;
  margin-bottom: 2em;
  border-radius: .3em;
  box-shadow: 1px 3px 7px -2px #777;
  -webkit-box-shadow: 1px 3px 7px -2px #777;
  -moz-box-shadow: 1px 3px 7px -2px #777;
  display: flex;
  flex-direction: column;
  transition: .25s ease;
}

.vert-expand {
  display: flex;
}

.post:hover {
  -webkit-box-shadow: 1px 9px 7px -2px #888;
  -moz-box-shadow: 1px 9px 7px -2px #888;
  box-shadow: 1px 9px 7px -2px #888;
  transition: .25s ease;
}

.post > footer .stats {
    cursor: default;
    list-style: none;
    padding: 0;
}

.post > footer .stats > :first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
}

.post > footer {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.post > footer .stats li {
    border-left: solid 1px rgba(160, 160, 160, 0.3);
    display: inline-block;
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 0.6em;
    font-weight: 400;
    letter-spacing: 0.25em;
    line-height: 1;
    margin: 0 0 0 2em;
        margin-left: 2em;
    padding: 0 0 0 2em;
        padding-left: 2em;
    text-transform: uppercase;
}

.post > footer ul {
  margin: 0 0 1em 0;
}

.post > footer ul.actions li {
  padding: 0 0 0 1.2em !important;
}

.post > footer .actions {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  margin-left: -1.2em;
}

.button.primary {
    background-color: #dbdbdb;
    color: #5b5b5b;
}

.button.primary:hover {
    background-color: gray;
}

@media only screen and (max-width: 480px) {
  ul.actions:not(.fixed) li {
    padding: .5em 0 .5em 0;
  }
  ul.actions:not(.fixed) li:first-child {
    padding-top: .5em;
  }
  .ptrail {
    margin-left: 0em !important;
    text-align: center;
  }
  .ptrail li {
    padding: 0 0 0 0em !important;
  }
  .ptrail li:first-child {
    margin: 0px;
  }
  input[type="submit"], input[type="reset"], input[type="button"], button, .button {
    padding: 0 2.9em;
  }
}

#footer .copyright a, #footer .copyright {
  text-decoration: none;
  color: #5b5b5b;
}

#footer .copyright a:hover {
  color: #000;
}

#footer .menu a {
    color: #5b5b5b;
}

.major.special.journal {
  padding: 0px;
}

//Core Media Landing Page Resolution Fix
@media only screen and (min-width: 2000px) {
    #banner {
        padding: 6em 2em 35em 2em;
    }
    body {
      background-size: auto, contain;

    &.landing {
      background-size: auto, contain;
    }
  }
}

@media only screen and (max-width: 1300px) {
    #banner {
        padding: 6em 2em 22em 2em;
    }
}
@media only screen and (max-width: 770px) {
    #banner {
        padding: 6em 2em 25em 2em;
    }
}

//Paginations

  .page_number {
    -moz-justify-content: right;
    -webkit-justify-content: right;
    -ms-justify-content: right;
    justify-content: right;
    display: flex;
  }

  .actions.pagination {
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    margin-top: 2em;
    margin-bottom: 0em;
  }

  .pagination .button.primary {
      background: #f2f2f2;
      color: #5b5b5b !important;
      border: 1px #b7b7b7 solid;
      font-weight: bold;
  }

  .pagination .button.primary li a {
      color: #5b5b5b;
  }

  //Page trail
  .ptrail {
    display: flex;
    align-items: center;
  }

  .ptrail li {
    height: auto;
    width: 3em;
    border: 1px solid #b7b7b7;
    background-color: #f2f2f2;
    border-radius: .25em;
    margin-left: .25em;
    font-weight: bold;
    text-align: center;
    padding: 0px !important;
  }

  .ptrail .selected {
      background-color: #6c6a6a;
  }

  .ptrail .selected a {
      color: white;
  }

//Products Listing Page

  //Product Categories Navbar
  .product-cat ul {
    list-style:none;
    display: flex;
    justify-content: space-between;
    padding: 1em;
  }
  .product-cat a {
    font-weight: bold;
  }
  //Product Headline Image
  .product-headline-flex {
    object-fit: cover;
    object-position: top;
    margin: 0px;
    width: 100%;
    height: 100%;
  }
  .product-hero-text p {
    padding-bottom: .5em;
  }
  @media only screen and (min-width: 1680px) {
    .product-hero-text p {
      padding-bottom: 1.5em;
    }
  }
  .headline-col-limit { //a weird bug when setting max-height on .product-headline-flex, therefore, setting col max-height did the trick.
    max-height: 25.5em;
  }

  .product a {
    text-decoration: none;
  }

  .product .image.fit {
      height: 12em;
  }

  .product img {
    border-radius: .3em .3em 0 0;
  }

  .product img.image.fit {
    margin: 0 0 1em 0;
    object-fit: cover;
  }

  .product p {
    margin-bottom: 1.5em;
  }

  .product p, .product h2, .product h3, .product h4, .product ul.actions {
    padding-left: .5em;
    padding-right: .5em;
  }

  .product ul.actions {
    margin-bottom: .5em;
    margin-top: auto;
  }

  .featured.product picture {
    object-fit:cover;
  }

  .featured.product h3, .featured.product h4 {
    padding-top: .5em;
  }

  .featured.product img.image.fit {
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 0em;
    border-bottom-left-radius: .3em;
  }



//Product Single Page

  //Product Shopping Cart
  .product-cart ul {
    list-style:none;
    padding: 1em;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 1em 0;
  }
  .product-cart a {
    text-decoration: none;
    font-weight: bold;
  }
  .product-cart span {
    padding: 0 .5em 0 .5em;
  }
  .product-cart .button.back {
    margin: 1.5em;
    font-weight: bold;
  }

  //Product Details
  .headline h1 {
    margin-bottom: 0em;
  }
  .product-details ul {
    list-style:none;
    padding-left: 0px;
    margin-bottom: .5em;
  }
  .product-details ul li {
    padding-left: 0px;
  }

  //Product Image
  .product-flex {
    max-height: 16em;
    min-height: 16em;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 16em;
    border-radius: .3em;
  }

  //Product Description
  .product-desc h3 {
    margin-bottom: 0em;
    margin-top: .5em;
  }

  .product-desc p {
    margin: 0 0 1.0em 0;
  }

  //Related Products Section
  .related-items {
    margin-top: 2em;
  }

  .related-products h3 {
    margin-bottom: .5em;
  }
