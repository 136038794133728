/* Basic */

	// MSIE: Required for IEMobile.
		@-ms-viewport {
			width: device-width;
		}

	// MSIE: Prevents scrollbar from overlapping content.
		body {
			-ms-overflow-style: scrollbar;
		}

	// Ensures page width is always >=320px.
		@include breakpoint('<=xsmall') {
			html, body {
				min-width: 320px;
			}
		}

	// Set box model to border-box.
	// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
		html {
			box-sizing: border-box;
		}

		*, *:before, *:after {
			box-sizing: inherit;
		}

	body {
		background-attachment: scroll, fixed;
		background-image: url('images/angular-lines-up.svg'), url('/assets/images/bg.webp');
		background-position: center -315px, center top;
		background-repeat: no-repeat;
		background-size: auto, auto;

		&.landing {
			background-attachment: scroll, fixed;
			background-image: url('images/angular-lines-up.svg'), url('/assets/images/bg.webp');
			background-position: center top, center top;
			background-size: auto, auto;
		}

		// Stops initial animations until page loads.
			&.is-preload {
				*, *:before, *:after {
					@include vendor('animation', 'none !important');
					@include vendor('transition', 'none !important');
				}
			}

		@include breakpoint('<=large') {
			background-attachment: scroll !important;
		}
	}
